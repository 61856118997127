import { Theme, css } from '@emotion/react'
import { Link } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

import { buildQueryParam } from '../../../../helpers'

const containerStyle = (theme: Theme) =>
    css({
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        display: 'block',
        textDecoration: 'none',
        margin: theme.spacing(1, 1, 2, 1),
        transition: 'all 0.5s ease',
        flexBasis: '31.77%',
        color: theme.sections.serviceList.foreground,
        '&:after': {
            content: '""',
            width: 0,
            height: 0,
            right: 0,
            bottom: 0,
            position: 'absolute',
            borderLeft: '19px solid transparent',
        },
        '& h4': {
            fontFamily: theme.fonts.default,
            fontWeight: 'bold',
            fontSize: '22px',
            lineHeight: '29px',
            margin: theme.spacing(0, 0, 2, 0),
            color: theme.sections.serviceList.serviceTitle,
        },
        '& p': {
            fontFamily: theme.fonts.default,
            fontWeight: 'normal',
            fontSize: 16,
            margin: 0,
            lineHeight: '21px',
        },
        [theme.breakpoints.down(theme.breakpoints.tablet + 48)]: {
            flexBasis: '48.33%', // 2 per line
        },
        [theme.breakpoints.down(theme.breakpoints.tablet)]: {
            flexBasis: '48%', // 2 per line
        },
        [theme.breakpoints.down(theme.breakpoints.smartphone + 96)]: {
            flexBasis: '98%', // 1 per line
        },
        // [theme.breakpoints.down(theme.breakpoints.small)]: {
        //   flexBasis: '98%', // 1 per line
        // },
        // '&:hover': {
        //   boxShadow: 'rgb(0 0 0 / 10%) 0px 20px 25px, rgb(0 0 0 / 4%) 0px 10px 10px',
        // },
        // border: '1px solid rgb(211, 211, 211)',
        // padding: theme.spacing(3),
        // boxSizing: 'border-box',
        // background: '#F8F8F8',
    })

interface ServiceItemProps {
    serviceTitle: string | null
    serviceSubTitle: string | null
    serviceSlug: string | null
    categorySlug?: string | null
    personaSlug?: string | null
    categoryName?: string | null
    categoryDescription?: string | null
}

const ServiceItem: FC<ServiceItemProps> = (props) => {
    return (
        <Link
            to={`/services/${props.serviceSlug}${buildQueryParam({
                category: props.categorySlug,
                persona: props.personaSlug,
            })}`}
            css={[containerStyle]}
        >
            <h4>{props.serviceTitle}</h4>
            <p>{props.serviceSubTitle}</p>
        </Link>
    )
}

export default ServiceItem
